import '@/css/app.css'
import Headroom from 'headroom.js'

import Alpine from 'alpinejs'
import collapse from '@alpinejs/collapse'
import focus from '@alpinejs/focus'
import persist from '@alpinejs/persist'

import customSelect from 'custom-select'

// Function to load and initialize components dynamically
const loadComponents = async (compArr = null) => {
    const components = compArr ?? [
        'cart',
        'advancedFilter',
        'fancybox',
        'swiper',
        'accordionComp',
        'flashMessage',
        'configurator'
    ]

    const importPromises = []

    for (const comp of components) {
        const compElements = document.querySelectorAll(`.${comp}`)
        if (compElements.length === 0) {
            continue
        }

        const importPromise = import(`./components/${comp}.js`).then(
            ({ init }) => {
                compElements.forEach((compElement) => {
                    // Initialize each component's element
                    importPromises.push(init(compElement))
                })
            }
        )

        importPromises.push(importPromise)
    }

    // Load additional base components only if needed
    if (!compArr) {
        importPromises.push(
            import('./base/mmenu.js').then(({ initMmenu }) => initMmenu()),
            import('./base/animations.js').then(({ initAnimations }) =>
                initAnimations()
            ),
            import('./base/smooth-scroll').then(({ initSmoothScroll }) =>
                initSmoothScroll()
            ),
            import('./base/flash-message').then(({ initFlashMessage }) => {
                initFlashMessage()
            })
        )
    }

    return await Promise.all(importPromises)
}
// Load components initially and initialize Alpine.js
loadComponents().then(() => {
    window.Alpine = Alpine

    Alpine.plugin(persist)
    Alpine.plugin(collapse)
    Alpine.plugin(focus)
    Alpine.start()
})

// App main
const main = () => {
    headroom()
    setCustomSelect()
    // loadComponents() is no longer needed here
}

const headroom = () => {
    const header = document.querySelector('header')
    if (header) {
        new Headroom(header, {
            offset: 100,
            onTop: () => {
                document.body.classList.add('headroom--top')
                document.body.classList.remove('headroom--not-top')
            },
            onNotTop: () => {
                document.body.classList.remove('headroom--top')
                document.body.classList.add('headroom--not-top')
            }
        }).init()
    }
}

// Initialize custom select elements
const setCustomSelect = () => {
    let customSelectInstances = []

    const initCustomSelects = () => {
        // Destroy existing instances to avoid duplicates
        customSelectInstances.forEach((csl) => csl.destroy())
        customSelectInstances = []

        // Reinitialize custom selects on all select elements
        document.querySelectorAll('select').forEach((select) => {
            const csl = customSelect(select)[0]
            customSelectInstances.push(csl)
        })
    }

    // Re-initialize on HTMX swaps
    if (typeof htmx !== 'undefined') {
        htmx.on('htmx:afterSwap', (event) => {
            // Initialize select elements and only swiper component after HTMX swap
            initCustomSelects()
            loadComponents(['swiper']) // Reload only Swiper
        })
    }

    initCustomSelects()
}

main()
